import { ButtonInterface } from '../../../utils/typings/Shared'



const Button = ({ children, className, onClick, icon, type='button', theme, disabled=false, prepend=false }: ButtonInterface) => {
    return (
        <button disabled={disabled} onClick={onClick} type={type} className={`${className} ${theme ? theme : disabled ? 'bg-lightGray text-gray' : ' bg-teal text-white '} ${icon && ' flex '} items-center gap-2 rounded-md px-6 py-3`}>
            { prepend && icon ? icon : null}
            { children }
            { !prepend && icon ? icon : null}
        </button>
    )
}

export default Button